<template>
	<div class="info" :class="[className, behind ? 'behind' : '']">
		<transition appear name="bounce" enter-active-class="bounceIn" >
			<img @click="refresh" class="logo" alt="Vue logo" src="../assets/img/logo.png" style="animation-duration: .5s" />
			
		</transition>
		<transition appear name="bounce" enter-active-class="bounceInLeft" >
			<h1 v-html="msg" class="title"></h1>
		</transition>
		<div class="button-content">
			<transition appear name="bounce" enter-active-class="bounceInLeft" >
				<a @click="promoClicked" v-if="btn" class="button" :class="btnClass">{{ btn }}</a>
			</transition>			
			<transition appear name="bounce" enter-active-class="bounceInLeft" >
				<p @click="promoClicked" v-if="icon" v-html="icon"></p>
			</transition>
		</div>
		<date-picker 
			v-if="showDate" 
			locale="es"
			v-model="selectedDate" 
			@dayclick="dateSelected"
			:max-date="new Date()"
			color="orange"
		/>
	</div>
</template>

<script>

import DatePicker from 'v-calendar/lib/components/date-picker.umd'

	export default {
		name: 'Promo',
		components: {
			DatePicker
		},
		props: {
			msg: String,
			btn: String,
			btnClass: {
				default: '',
				type: String,
			},
			icon: {
				default: '',
				type: String,
			}
		},
		computed: {
			className() {
				return this.$route.name.toLowerCase() + '-info'
			},
			behind() {
				return this.$store.getters.showDetail
			}
		},
		data() {
			return {
				selectedDate: null,
				showDate: false,
			}
		},
		methods: {
			promoClicked() {
				if(this.$route.name == 'Score') {
					this.showDate = !this.showDate
				} else {
					this.$emit('promoClicked')
				}
			},
			dateSelected(payload) {
				this.$store.dispatch('getScores', payload.id)
				this.showDate = false
			},
			refresh(){
				window.location.href = '/'
			}
		}
	} 
</script>

<style lang="scss">

.info {
	position: absolute;
	height: 100%;
    padding-top: 50px;
    padding-left: 3.229vw;
	z-index: 4;

	.logo {
		cursor: pointer;
	}

	img {
		width: 26.875vw;
		height: auto;
	}	

    h1 {
        @extend %medium;
		width: 100%;
		color: $orangeTheme;
		font-size: 63px;
		line-height: .9;
		margin-top: 50px;
		margin-left: 1.8vw;

        span {
			@extend %black;	
			color: $white;		
            font-size: 46px;
        }

        b {
			@extend %black;
			display: inline-block;
			font-size: 132px;
			line-height: 1.1;
			margin-bottom: -7px;
        }
	}

	.button {
		margin-top: 10px;
		margin-left: 1.8vw;		
	}

	&.about-info {
		height: 0;
		margin: 0;
		position: absolute;
		top: 9%;
		left: 3.4%;
	}
	
	&.score-info {
		height: 0;
		margin: 0;
		position: absolute;
		top: -890px;
		left: 3%;

		h1 {
			margin-top: 22px;
		}

		.button-content {
			@include flex-container;
		}

		.button {
			margin-left: 20px;
		}

		.icon {
			@include effect(all, .3s, ease-out);
			cursor: pointer;
			width: 80px;
			height: 80px;
			margin-left: 35px;

			@include nomobile {

				&:hover, &:active, &:focus {
					fill: $orangeTheme;						
				}
			}			
		}

	}
	
	&.contact-info, &.rule-info, &.playground-info {
		height: 0;
		margin: 0;
		position: absolute;		
		margin-top: -36%;
    	margin-left: 4%;
	}

	&.help-info {
		margin-left: 3.5%;
	}
	
	&.playground-info {
		margin-top: -22%;
			
		img {
			margin-left: -10%;
		}
	}

	&.notfound-info {
		margin-top: -45%;
	}
	
	.vc-container {
		margin-top: 10px;
		margin-left: 40px;
	}
}

@include tabletLandscape {

	.info {
		@include flex-container(center, center, center, nowrap, column);
		position: relative;
		padding: 20px;
		width: 100%;
		margin-top: 100px;

		img {
			width: 45%;
		}

		h1 {
			text-align: center;
		}

		&.about-info {
			top: 35%;
			left: 0;
			margin: 0 auto;
		}

		&.score-info {
			top: 0;
			left: 0;
			margin: -38% auto 0;			
			z-index: 5;
		}

		&.playground-info {
			top: 0;
			left: 0;
			margin: -10% auto 0;			
			z-index: 5;

			img {
				margin-left: 0;
				width: 25%;
			}
		}		

		&.contact-info, &.rule-info, &.help-info {
			margin-left: 0;
		}		

		.vc-container {
			position: absolute;
			margin: 81% auto 0;
		}		
	}
}

@include mobile {

	.info {
		margin: -38% auto;
		padding: 0;
		text-align: center;
		width: 100%;

		img {
			width: 79%;
		}

		h1 {
			margin-top: 73px;
			font-size: 50px;
			margin-left: 0;

			span {
				font-size: 36px;
			}

			b {
				font-size: 102px;
			}
		}

		.button {
			margin-top: 48px;
			margin-left: 0;
		}

		&.about-info {
			margin: 0 auto;
			left: 0;
			top: 23%;

			img {
				width: 42%;
			}
		}	
		
		&.score-info {
			margin: 0 auto;
			left: 0;
			top: -110px;			

			img {
				width: 42%;
			}

			.button {
				margin-top: 5px;
			}

			.icon {
				width: 40px;
				height: 40px;
				margin-left: 20px;
			}		
		}

		&.contact-info, &.rule-info {			
			margin-top: -16%;

			img {
				width: 44%;
			}
		}

		&.help-info {

			img {
				width: 42%;
			}
		}

		&.playground-info {
			margin-top: -10%;

			img {
				width: 42%;
			}

			&.behind {
				z-index: 4;
			}
		}
		
		.vc-container {
			margin: 0 auto;
			top: 95px;
		}
	}	
}
</style>